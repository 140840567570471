<template>
  <div>
    <button
      type="button"
      class="btn btn-main button mb-2 btn-cat"
      @click="createMainCategory"
    >Create Root Category</button>
    <button
      type="button"
      class="btn btn-main button btn-cat"
      @click="createSubCategory"
    >Create Subcategory</button>

    <div class="mt-3 list-cat">
      <template v-for="(item, key) in items">
        <div :key="`lv2-${key}`">
          <div>
            <div class="pl-lv2 py-2">
              <div :class="{ menuactive: isActive(item.id) }">
                <b-button
                  variant="toggle-tree"
                  v-if="parentList.indexOf(item.id) < 0 && item.subcategory.length > 0"
                  @click="addParent(item.id)"
                  ref="expandAll"
                  class="mr-2"
                >
                  <font-awesome-icon icon="plus" />
                </b-button>
                <b-button
                  variant="toggle-tree"
                  class="mr-2"
                  v-if="parentList.indexOf(item.id) >= 0 && item.subcategory.length > 0"
                  @click="deleteParent(item.id)"
                >
                  <font-awesome-icon icon="minus" />
                </b-button>
                <span
                  @click="getCategoryData(item.id, 'maincategory')"
                >{{item.name}} ({{item.subcategory.length}})</span>
              </div>
            </div>
          </div>
        </div>
        <template v-for="(item2, key2) in item.subcategory">
          <div :key="`lv2-${key}-lv3-${key2}`" v-if="parentList.indexOf(item.id) > -1">
            <div class="text-left pl-lv3">
              <div :class="{ menuactive: isActive(item2.id) }" class="pl-lv3-box">
                <span @click="getCategoryData(item2.id, 'subcategory')" v-if="item2.enabled == true">
                  <font-awesome-icon
                    :icon="['fas', 'circle']"
                    class="circle"
                  />
                  {{item2.name}}
                </span>
                <span @click="getCategoryData(item2.id, 'subcategory')" v-else>
                  <font-awesome-icon
                    :icon="['fas', 'circle']"
                    class="circle"
                  />
                  {{item2.name}}
                </span>
              </div>
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    items: {
      required: true,
      type: Object | Array
    },
    activeItem: {
      required: false,
      type: Number
    },
    parentList: {
      required: true,
      type: Object | Array
    }
  },
  watch: {
    parentList (val) {
      // console.log('value', val);
    },
    activeItem (val) {
      // console.log('value', val);
    },
  },
  methods: {
    createMainCategory() {
      this.$emit("handleSetMainCategoryData");
    },
    createSubCategory() {
      this.$emit("handleSetSubCategoryData",this.activeItem);
    },
    isActive: function(menuItem) {
      return this.activeItem == menuItem;
    },
    addParent(ref) {
      this.parentList.push(ref);
    },
    deleteParent(ref) {
      var index = this.parentList.indexOf(ref);
      if (index !== -1) this.parentList.splice(index, 1);
    },
    getCategoryData(id, name) {
      this.$emit("handleGetCategoryData", id, name);
    }
  }
};
</script>

<style scoped>
.pl-lv2 {
  color: #0d1730;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
}

.pl-lv2 span {
  cursor: pointer;
}

.pl-lv3 span {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #0d1730;
  font-size: 16px;
  cursor: pointer;
}

.pl-lv3-box,
.pl-lv3-box .menuactive {
  padding-left: 7% !important;
  margin-left: 10px;
  margin-right: 10px;
}


.pl-lv4-box,
.pl-lv4-box .menuactive {
  padding-left: 20% !important;
}

.btn-cat {
  width: 100%;
}

.menuactive {
  font-weight: bold;
  background-color: var(--secondary-color);
  padding: 5px 5px;
  border-radius: 0.25rem;
  color: #fff;
}

.menuactive span {
  color: #fff;
}

.circle {
  width: 5px;
}
.list-cat {
  border: 1px solid #d8dbe0
}
</style>
